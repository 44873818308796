
import { IResponseData, RouterName } from "@/models/common";
import { useStore } from "vuex";
import { computed, defineComponent, onMounted, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { IRequestSummary, RequestKindTypes } from "@/models/request";
import { AxiosResponse } from "axios";
import $API from "@/services";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import { IDcotorListItem, PossibleTypes } from "@/models/doctor";
export default defineComponent({
  name: "Mypage Layout",
  setup() {
    const { t } = useI18n({ useScope: "global" });

    const path = reactive({
      Profile: { name: RouterName.PROFILE },
      Password: { name: RouterName.PASSWORD },
      OrderList: { name: RouterName.ORDER_LIST },
      MyDoctor: { name: RouterName.MY_DOCTOR },
    });
    const store = useStore();
    const accountInfo = reactive({
      name: store.getters.getAccountInfo?.Name,
      email: store.getters.getAccountInfo?.UserName,
    });

    const summary = reactive({
      OnlineCare: null as IRequestSummary | null,
      SecondOpinion: null as IRequestSummary | null,
      Doctor: null as Array<IDcotorListItem> | null,
      isLoading: false,
    });

    const getOnlineCareSummary = async () => {
      const res: AxiosResponse<IResponseData<IRequestSummary>> =
        await $API.RequestService.getRequsetSummary(
          RequestKindTypes.OnlineCare
        );
      const {
        data: { ResultData },
      } = res;
      if (Array.isArray(ResultData)) return;

      summary.OnlineCare = ResultData;
    };

    const getSecondOpinionSummary = async () => {
      const res: AxiosResponse<IResponseData<IRequestSummary>> =
        await $API.RequestService.getRequsetSummary(
          RequestKindTypes.SecondOpinion
        );
      const {
        data: { ResultData },
      } = res;
      if (Array.isArray(ResultData)) return;

      summary.SecondOpinion = ResultData;
    };

    const getDoctorCount = async () => {
      const res: AxiosResponse<IResponseData<IDcotorListItem>> =
        await $API.DoctorService.getDoctorList(PossibleTypes.ALL);
      const {
        data: { ResultData },
      } = res;
      if (!Array.isArray(ResultData)) return [];
      summary.Doctor = ResultData;
    };

    const DoctorCount = computed(() => {
      if (summary.Doctor)
        return summary.Doctor?.filter((x) => x.MyDoctor).length;
      return "-";
    });
    const ServiceCount = computed(() => {
      if (summary.OnlineCare && summary.SecondOpinion) {
        const p = {
          onlineCare: {
            response:
              summary.OnlineCare.Canceled.valueOf() +
              summary.OnlineCare.Completed.valueOf(),
            request:
              summary.OnlineCare.Requested.valueOf() +
              summary.OnlineCare.Scheduled.valueOf(),
          },
          secondOpinion: {
            response:
              summary.SecondOpinion.Canceled.valueOf() +
              summary.SecondOpinion.Completed.valueOf(),

            request:
              summary.SecondOpinion.Requested.valueOf() +
              summary.SecondOpinion.Scheduled.valueOf(),
          },
        };
        return p;
      }
      return {
        onlineCare: {
          request: "-",
          response: "-",
        },
        secondOpinion: {
          request: "-",
          response: "-",
        },
      };
    });

    onMounted(async () => {
      try {
        store.commit(CommonMutationTypes.SET_IS_LODING, true);

        await getOnlineCareSummary();
        await getSecondOpinionSummary();
        await getDoctorCount();

        summary.isLoading = true;
      } catch (e) {
        console.error(e);
      } finally {
        store.commit(CommonMutationTypes.SET_IS_LODING, false);
      }
    });

    return { t, path, accountInfo, ServiceCount, summary, DoctorCount };
  },
});
